import React, {createContext}from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom'
import App from './Components/App';
import { Provider } from 'mobx-react';
import { Provider as ReduxProvider } from 'react-redux';
import reduxStore from './store/reduxStore/store';

import './index.scss';
import Store from './store/store'
import AuthStore from './store/authStorage'
import TodoStore from './store/todoStore'
import { useLayoutEffect } from 'react';
import {useLocation } from 'react-router-dom';
import WordsStore from './store/wordsStore';

const container = document.getElementById("root")
const root = createRoot(container)


export const store = new Store() 
export const authStore = new AuthStore()
export const todoStore = new TodoStore()
export const wordsStore = new WordsStore()
export const wordsContext = createContext({wordsStore})
export const authContext =  createContext({authStore})
export const Context = createContext({store})
export const todoContext = createContext({todoStore})



const Wrapper = ({ children }) => {
    const location = useLocation();
    
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    
    return children;
  };



root.render(
  <ReduxProvider store = {reduxStore}>
    <Provider value = {{authStore, store, todoStore, wordsStore}}>
     <BrowserRouter>
     <Wrapper>
    <App />
    </Wrapper> 
    </BrowserRouter>
    </Provider>
    </ReduxProvider>
)
